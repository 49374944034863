// Import action type from an external file
import * as actionTypes from './actionTypes'

// Define the initial state for the Redux store
const INITIAL_STATE = {
  loading: false,
  blogListData: [],
  featuredBlog: {},
  totalBlogCount: 0,
  blogDetails: null,
  subscribeLoading: false,
}

// Define a Redux reducer function
const blogReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.GET_BLOG_LIST_DATA_INIT:
      return {
        ...state,
        loading: true,
        blogListData: [],
        totalBlogCount: 0,
        featuredBlog: {},
      }
    case actionTypes.GET_BLOG_LIST_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        blogListData: payload?.data,
        totalBlogCount: payload?.count,
        featuredBlog: payload?.featured,
      }
    case actionTypes.GET_BLOG_LIST_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.GET_BLOG_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        blogDetails: payload,
      }
    case actionTypes.GET_BLOG_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.SUBSCRIBE_TO_NEWSLATTER_INIT:
      return {
        ...state,
        subscribeLoading: true,
      }
    case actionTypes.SUBSCRIBE_TO_NEWSLATTER_SUCCESS:
      return {
        ...state,
        subscribeLoading: false,
      }
    case actionTypes.SUBSCRIBE_TO_NEWSLATTER_FAIL:
      return {
        ...state,
        subscribeLoading: false,
      }
    default:
      return state
  }
}

export default blogReducer
